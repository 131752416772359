import { useTranslation } from 'react-i18next';
import { Text } from '@veo/web-design-system';

import { useAuthPlayer } from 'src/features/auth';
import { FilterParams } from 'src/common/hooks/useQueryParams';
import { FilterItem } from './quick-filter-item';

import styles from './quick-filters.module.scss';

type Props = {
  onChange(filter: FilterParams): void;
};

export function QuickFilters({ onChange }: Props) {
  const { data: authPlayer } = useAuthPlayer();

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Text type="h4" size="h4" weight="medium">
        { t('add_player_clips.filters.quick_filters_label') }
      </Text>
      <div className={styles.container}>
        <FilterItem
          filter={{ bookmarked: true }}
          icon="bookmark"
          label={t('add_player_clips.filters.saved_for_later_filter_label')}
          onClick={onChange}
        />
        <FilterItem
          filter={{ involvedPlayer: authPlayer!.externalId }}
          icon="at-sign"
          label={t('add_player_clips.filters.mentions_filter_label')}
          onClick={onChange}
        />
        <FilterItem
          filter={{ creator: authPlayer!.externalId }}
          icon="plus-square"
          label={t('add_player_clips.filters.created_by_me_filter_label')}
          onClick={onChange}
        />
      </div>
    </div>
  );
}

import { FormField, Input } from '@veo/web-design-system';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isJerseyNumberValidator } from 'src/common/utils/form-validators';
import { useState } from 'react';
import { AboutInfoFormValues } from '../../../utils/about-information';
import styles from './about-information-form.module.scss';

const inputName = 'jerseyNumber';

export function JerseyField() {
  const { t } = useTranslation();

  // For some reason extracting `error` from `fieldState` isn’t working.
  // The validator is returning the correct error string when the input value is
  // wrong but the `error` object is never populated accordingly (it is always
  // `undefined`).
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    control,
    register,
  } = useFormContext<AboutInfoFormValues>();

  const {
    fieldState: {
      isDirty,
    },
  } = useController<AboutInfoFormValues, typeof inputName>({
    control,
    name: inputName,
    rules: {
      validate: (value) => {
        const result = isJerseyNumberValidator(value);
        if (typeof result === 'string') {
          setErrorMessage(result);
        } else {
          setErrorMessage(undefined);
        }
        return result;
      },
    },
  });

  return (
    <FormField
      className={styles.field}
      error={isDirty ? errorMessage : undefined}
      id={inputName}
      label={t('player_about.form.jersey_number_field_label')}
    >
      <Input
        {...register(inputName)}
        inputMode="numeric"
        maxLength={2}
        pattern="^\d{1,2}$"
        type="text"
      />
    </FormField>
  );
}

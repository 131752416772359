/* eslint-disable no-plusplus */
import {
  isBoolean, isEmpty, isNumber, isObject, isString,
} from 'lodash-es';
import { DateTime } from 'luxon';
import i18n from 'src/common/services/i18next';

export type ValidationResult = string | null;

type ValidatorFn<T extends unknown = string> = (value?: T, message?: string) => ValidationResult;

export const validate = (value: any) => (validators: ValidatorFn[]) => {
  let result: ValidationResult = null;

  for (let index = 0; index < validators.length; index++) {
    const fieldValidator = validators[index];

    result = fieldValidator(value);

    if (result !== null) {
      break;
    }
  }

  return result;
};

export const isUrlValidator = (value?: string, message?: string) => {
  const error = message ?? i18n.t('validators.url') as string;

  if (!value) {
    return true;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const noop = new URL(value);

    return true;
  } catch (err) {
    return error;
  }
};

export const isSocialMediaIdentifierValidator = (value?: string, message?: string) => {
  const error = message ?? i18n.t('validators.social_media_identifier') as string;

  if (!value) {
    return true;
  }

  if (isUrlValidator(value) === true) {
    return error;
  }

  return true;
};

export const isRequiredValidator = (
  value?: string,
  message?: string,
) => {
  const error = message ?? i18n.t('validators.required') as string;

  if (!isBoolean(value) && !value) {
    return error;
  }

  if (isString(value) && value.trim() === '') {
    return error;
  }

  if (Array.isArray(value) && !value.length) {
    return error;
  }

  if (isObject(value) && isEmpty(value)) {
    return error;
  }

  return true;
};

export const isNumberValidator = (value?: string, message?: string): boolean | string => {
  const error = message ?? i18n.t('validators.integer') as string;

  if (!value) {
    return true;
  }

  const numberRegexp = /^\d+(\.\d+)?$/;

  if (!numberRegexp.test(value)) {
    return error;
  }

  return true;
};

export const isMaxLengthValidator = (max: number) => (value?: string, message?: string) => {
  const error = message ?? i18n.t('validators.max_length', { max }) as string;

  if (value === '' || !value) {
    return true;
  }

  const trimmedValues = value.trim();

  if (trimmedValues === '' || trimmedValues.length > max) {
    return error;
  }

  return true;
};

export const isNumberInRangeValidator = (min: number, max: number) => (value?: string, message?: string): boolean | string => {
  const error = message ?? i18n.t('validators.range', { min, max }) as string;
  if (value === '' || !value) {
    return true;
  }

  const formattedValue = parseFloat(value);

  if (formattedValue && (!isNumber(formattedValue) || formattedValue < min || formattedValue > max)) {
    return error;
  }

  return true;
};

export const isJerseyNumberValidator = (value?: string, message?: string) => {
  const error = message ?? i18n.t('validators.range', { min: '0/00', max: '99' });

  if (value === '' || !value) {
    return true;
  }

  const jerseyNumberRegex = /^\d{1,2}$/;

  if (!jerseyNumberRegex.test(value) && typeof error === 'string') {
    return error;
  }

  return true;
};

export const isDateInRangeValidator = (min?: string, max?: string) => {
  let minDateTime: DateTime | null = null;
  let maxDateTime: DateTime | null = null;

  if (min) {
    minDateTime = DateTime.fromISO(min);
  }

  if (max) {
    maxDateTime = DateTime.fromISO(max);
  }

  return (value?: string, message?: string) => {
    const error = message ?? i18n.t('validators.dateRange', { min, max }) as string;

    if (!value) {
      return true;
    }

    const selectedDate = DateTime.fromISO(value);

    if (!selectedDate.isValid) {
      return error;
    }

    if (minDateTime && selectedDate < minDateTime) {
      return error;
    }

    if (maxDateTime && selectedDate > maxDateTime) {
      return error;
    }

    return true;
  };
};
